<template>
  <div id="radar3">
    <!-- 选择后的状态 -->
    <div class="box_frame" :class="$store.state.lang == 'en' ? 'english' : ''">
      <div class="open_button" v-show="is_open" @click="change_open"></div>
      <!-- 内容盒子 -->
      <div class="content_box" v-show="is_null">
        <!-- 头部盒子 -->
        <div class="header_title">
          <!--选择盒子 -->
          <div class="left_click_box">
            <!-- 选择图片-->
            <div class="left_choose_img" v-show="attributeAllList.length != 0 && $store.state.lang == 'cn'">
              <img src="https://newstore.vynior.com/choose.png" alt="" />
              <el-cascader
                class="img-cascader"
                :options="attributeAllList"
                :props="{ multiple: true, emitPath: true }"
                :value="values"
                ref="cascader"
                @change="cascaderChange"></el-cascader>
            </div>
            <!-- EN选择图片-->
            <div class="left_choose_img" v-show="attributeAllList.length != 0 && $store.state.lang == 'en'">
              <img src="https://newstore.vynior.com/xuanzechoo.png" alt="" />
              <el-cascader
                class="img-cascader"
                :options="attributeAllList"
                :props="{ multiple: true, emitPath: true }"
                :value="values"
                @change="cascaderChange"></el-cascader>
            </div>
            <!--空的选择图片 -->
            <div class="left_choose_img" v-show="attributeAllList.length == 0 && $store.state.lang == 'cn'">
              <img src="https://newstore.vynior.com/gray_choose.png" alt="" />
            </div>
            <!--空的选择图片 -->
            <div class="left_choose_img" v-show="attributeAllList.length == 0 && $store.state.lang == 'en'">
              <img src="https://newstore.vynior.com/grayXZ.png" alt="" />
            </div>
            <!-- 重置 -->
            <div class="left_reset_img" @click="Reset" v-show="$store.state.lang == 'cn'">
              <img src="https://newstore.vynior.com/czsscz.png" alt="" />
            </div>
            <!-- EN重置 -->
            <div class="left_reset_img" @click="Reset" v-show="$store.state.lang == 'en'">
              <img src="https://newstore.vynior.com/GroupReset.png" alt="" />
            </div>
          </div>

          <!-- 名称盒子 -->
          <div class="right_name_box" v-show="show_productName.length != 0">
            <div class="name" v-for="(ite, inde) in show_productName" :key="inde">
              <div class="name_box">
                <el-tooltip :open-delay="500" effect="dark" placement="top" popper-class="back_ground">
                  <div slot="content" style="line-height: 18px">
                    <p>{{ ite.program }}</p>
                    <p>{{ ite.product }}</p>
                  </div>
                  <p>
                    {{ ite.program }} <br />
                    {{ ite.product }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </div>

          <!-- kkk名称盒子 -->
          <div class="right_name_boxs" v-show="show_productName.length == 0">
            <div class="name1">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="nameed">-- --</div>
          </div>

          <!-- open/close按钮 -->
          <div class="close_button" @click="change_show"></div>
        </div>

        <!-- data盒子 -->
        <div class="data_bycical">
          <!-- 属性名称展示 -->
          <div class="data_attribute" v-show="attributeAllList.length != 0">
            <div class="attribute_data_name" v-for="(items, indexs) in attributeName" :key="indexs">
              {{ items }}
            </div>
          </div>
          <!-- kkk属性名称展示 -->
          <div class="data_attributes" v-show="attributeAllList.length == 0">
            <div class="attribute_data_name">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="attribute_data_named">--</div>
          </div>

          <!-- 数据展示 -->
          <div class="data_num" v-show="show_productName.length != 0">
            <div class="attribute_data_num" v-for="(item, index) in show_productData" :key="index">
              <div class="attribute_data_nums" v-for="(ite, ind) in item.panel_attributes_data" :key="ind">
                <div class="items">
                  {{ ite }}
                </div>
              </div>
            </div>
          </div>
          <!--kkk 数据展示 -->
          <div class="data_nums" v-show="show_productName.length == 0">
            <img src="https://newstore.vynior.com/null.png" alt="" />
            <span>{{ $t(`message.History.NoData`) }}</span>
          </div>
        </div>
      </div>

      <!-- 雷达图 -->
      <div
        class="box_data_yc_radar radar_position"
        :class="is_open == 1 ? 'box_data_yc_radars' : 'box_data_yc_radarss'"
        v-show="show_productName.length != 0">
        <div id="myRadar" class="echart" > </div>

        <div class="chart-legend" >
          <div class="chart-legend-f">
            <div    
              class="chart-legend-for"
              v-show="show_radars_data[index].name != undefined && index >= 4"
              v-for="(item, index) in show_productName"
              :key="index">
              <div class="legend-for-back" :style="{ 'background-color': colorArr[index] }"></div>
              <div class="legend-for-text" >
                <el-tooltip
                  :open-delay="500"
                  class="item"
                  effect="dark"
                  :visible-arrow="false"
                  :content="''"
                  placement="top"
                  popper-class="back_ground">
                  <div slot="content">{{ item.program }}<br />{{ item.product }}</div>
                  <el-button>
                    {{ item.program }}<br />
                    {{ item.product }}
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="chart-legend-f">
            <div
              class="chart-legend-for"
              v-show="show_radars_data[index].name != undefined && index < 4"
              v-for="(item, index) in show_productName"
              :key="index">
              <div class="legend-for-back" :style="{ 'background-color': colorArr[index] }"></div>
              <div class="legend-for-text">
                <el-tooltip
                  :open-delay="500"
                  class="item"
                  effect="dark"
                  :visible-arrow="false"
                  :content="''"
                  placement="top"
                  popper-class="back_ground">
                  <div slot="content">{{ item.program }}<br />{{ item.product }}</div>
                  <el-button>
                    {{ item.program }}<br />
                    {{ item.product }}
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- kkkk雷达图 -->
      <div class="box_none_yc_radar" :class="pradar == 1 ? 'add_left' : ''" v-show="show_productName.length == 0">
        <img src="https://newstore.vynior.com/null2.png" alt="" />
        <span> {{ $t(`message.History.empty`) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      radius: '45%', //雷达图放大比例
      values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], //选中的属性下标
      colorArr: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
      pradar: 0,
      // _product_name: [],
      mid_product_data: [], //中转赋值 data
      show_radar_att_name: [], //展示 雷达图 属性名称

      show_radars_data: [
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
      ], //雷达图的数据
      show_product_name: [],
      show_product: [], //展示的产品名称
      panel_item: [], //存储视图  属性名称 循环的数组
      old_radars_data: [],
      mid_radars_data: [],
      is_null: 1,
      is_open: 0,
      isNull: 1,
      showitem: false,
      itenname: '',
      itemleft: '',
      attributeAllList: [], //1.1完整的属性名称
      res_panelData: [], //1.1接收paneldata的数据
      attribute_Index: [], //1.1选择属性的下标;
      attributeName: [], //1.1展示属性名称
      show_productData: [], //1.1展示的产品数据
      show_productName: [], //1.1展示的产品名字
    };
  },

  watch: {
    //监听 store:选择对比产品的名字
    '$store.state.likeProductList': {
      handler(newval) {
        this.show_productName = newval.slice(0, 8);
        //console.log("newval :>> ", newval);

        this.show_productName.forEach((item) => {
          this.show_product_name.push(item.product);
        });
      },
    },
    //监听 store:属性维度
    '$store.state.likeNameList': {
      handler(newval) {
        this.attributeAllList = newval;
        this.attributeName = newval.slice(0, 10);
        //console.log("this.attributeAllList :>> ", this.attributeAllList);
        if (newval != '') {
          let res = newval;
          let that = this;
          let arr = [];
          res.forEach((item, index) => {
            // //console.log(item,index,'itemindex');
            let obj = {};
            obj.label = item;
            obj.value = index;
            arr[index] = obj;
          });
          //console.log("arr :>> ", arr);
          that.attributeAllList = arr;
          //console.log("that.attributeAllList :>> ", that.attributeAllList);
        }
      },
    },
    //监听 store:分析数据
    '$store.state.forcast_data': {
      handler(newval) {
        //console.log("panel_Datanewval :>> ", newval);
        this.res_panelData = newval;

        this.mid_product_data = [
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
        ];

        //将数据处理为单列数组，给页面展示
        for (let i = 0; i < this.mid_product_data.length; i++) {
          for (let j = 0; j < this.res_panelData.length; j++) {
            if (this.res_panelData[j][i] != undefined) {
              this.mid_product_data[i].panel_attributes_data.push(this.res_panelData[j][i].toFixed(2));
            }
          }
        }

        this.managePaneldata();
      },
    },
    //监听 刻度数字显示
    '$store.state.line_num': {
      handler() {
        this.show_radar_num();
      },
    },
    //监听 展示的属性名字
    attributeName: {
      handler(newval) {
        //console.log("newval :>> ", newval);
        //console.log("oldval :>> ", oldval);
        //处理雷达图的属性名称
        this.show_radar_att_name = newval.map((item) => ({
          name: item,   
          axisLabel: { show: false },
        }));
        this.radars();
      },
    },
    "$store.state.lang" :{
      handler(){
           if (this.$store.state.likeNameList.length != 0) {
      this.attributeAllList =this.$store.state.lang =="cn" ? this.$store.state.likeNameList : this.$store.state.likeNameList_en
      //属性名称(维度)
      {
        let res = JSON.parse(JSON.stringify(this.attributeAllList));

        this.attributeName = res.slice(0, 10);
        //console.log("this.atrributeAll :>> ", this.atrributeAll);
        //console.log("this.attributeName :>> ", this.attributeName);
        this.show_radar_att_name = this.attributeName.map((item) => ({
          name: item,
          axisLabel: { show: false },
        }));
        this.radars();
        let that = this;
        let arr = [];
        res.forEach((item, index) => {
          // //console.log(item,index,'itemindex');
          let obj = {};
          obj.label = item;
          obj.value = index;
          arr[index] = obj;
        });
        //console.log("arr :>> ", arr);
        that.attributeAllList = arr;
        //console.log("that.attributeAllList :>> ", that.attributeAllList);
      }
    }
      if (this.$store.state.forcast_data.length != 0) {
      this.res_panelData = this.$store.state.forcast_data;
      //处理展示数据
      {
        //console.log("this.res_panelData :>> ", this.res_panelData);
        this.mid_product_data = [
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
        ];

        //将数据处理为单列数组，给页面展示
        for (let i = 0; i < this.mid_product_data.length; i++) {
          for (let j = 0; j < this.res_panelData.length; j++) {
            if (this.res_panelData[j][i] != undefined) {
              this.mid_product_data[i].panel_attributes_data.push(this.res_panelData[j][i].toFixed(2));
            }
          }
        }
        //console.log("this.mid_product_data :>> ", this.mid_product_data);
        let ten = [
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
        ];
        let middle = JSON.parse(JSON.stringify(this.mid_product_data));
        //console.log("this.middle :>> ", middle);
        //截取的数据10个数据长度,初始化展示data
        for (let i = 0; i < middle.length; i++) {
          ten[i].panel_attributes_data = middle[i].panel_attributes_data.splice(0, 10);
        }
        //console.log("ten :>> ", ten);
        this.show_productData = ten;
        //console.log("this.show_productData :>> ", this.show_productData);
        // 雷达图数据
        this.old_radars_data = [
          { value: [], name: ''},
          { value: [], name: ''},
          { value: [], name: ''},
          { value: [], name: ''},
          { value: [], name: ''},
          { value: [], name: ''},
          { value: [], name: ''},
          { value: [], name: ''},
        ];
        for (let index = 0; index < this.show_productName.length; index++) {
          this.old_radars_data[index].value = this.show_productData[index].panel_attributes_data;
          this.old_radars_data[index].name = this.show_productName[index].product;
        }
        this.show_radars_data = this.old_radars_data;

        //切除雷达图多余的数组
        let deleteArr = [];
        for (let x = 0; x < 8; x++) {
          if (this.show_radars_data[x].value.length == 0) {
            //console.log(x);
            deleteArr.push(x);
          }
        }
        //console.log("object :>> ", deleteArr[0], deleteArr.at(-1));
        this.show_radars_data.splice(deleteArr[0], deleteArr[deleteArr.length - 1]);
      }
    }
      }
    }
  },

  methods: {
    //选择器属性中的选中变化
    cascaderChange(e) {
      this.$store.commit('$_changeLine_num', false);
      ////console.log(e, "eee");
      let arr1 = []; //下标
      let arr2 = []; //名字
      e.forEach((item) => {
        arr1.push(item[0]);
        arr2.push(this.attributeAllList[item].label);
      });
      //console.log(arr1, "arr1");
      //console.log(arr2, "arr2");
      this.attributeName = arr2;
      this.show_productData = [
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
      ];
      //中转变量
      let transfer = JSON.parse(JSON.stringify(this.mid_product_data));
      let transfor_data = [];

      // 中转雷达图数据
      var mid_radars_data = [
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
      ];
      //拿到全部数据(包含undefined)
      for (let j = 0; j < arr1.length; j++) {
        for (let i = 0; i < transfer.length; i++) {
          transfor_data.push(transfer[i].panel_attributes_data[arr1[j]]);
        }
      }
      //处理数据剔除undefined
      var int = 0;
      for (let index = 0; index < transfor_data.length; index++) {
        // 循环给目标数组 赋值newProducts
        if (int > 7) {
          int = 0;
        }
        mid_radars_data[int].value.push(transfor_data[index]);
        // 展示对比数据处理
        this.show_productData[int].panel_attributes_data.push(transfor_data[index]);
        int = int + 1;
      }

      //处理雷达图的属性名称
      this.show_radar_att_name = arr2.map((item) => ({
        name: item,
        axisLabel: { show: false },
      }));

      for (let x = 0; x < this.show_radars_data.length; x++) {
        this.show_radars_data[x].value = mid_radars_data[x].value;
      }

      this.radars();
      //console.log("show_radars_data :>> ", mid_radars_data);
      //console.log("this.show_radars_data :>> ", this.show_radars_data);
      //console.log("transfor_data :>> ", transfor_data);
      //console.log("this.show_productData :>> ", this.show_productData);
    },
    //接收过来的数据处理1.1
    managePaneldata() {
      let ten = [
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
      ];
      let middle = JSON.parse(JSON.stringify(this.mid_product_data));
      //截取的数据10个数据长度,初始化展示data
      for (let i = 0; i < middle.length; i++) {
        ten[i].panel_attributes_data = middle[i].panel_attributes_data.splice(0, this.attributeName.length);
      }
      this.show_productData = ten;

      // 雷达图数据
      this.old_radars_data = [
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
        { value: [], name: '', max: 5 },
      ];
      for (let index = 0; index < this.show_productName.length; index++) {
        this.old_radars_data[index].value = this.show_productData[index].panel_attributes_data;
        this.old_radars_data[index].name = this.show_productName[index].product;
      }

      //赋值展示雷达图数据;
      this.show_radars_data = this.old_radars_data;
      //切除雷达图多余的数组
      let deleteArr = [];
      for (let x = 0; x < 8; x++) {
        if (this.show_radars_data[x].value.length == 0) {
          //console.log(x);
          deleteArr.push(x);
        }
      }
      //console.log("object :>> ", deleteArr[0], deleteArr.at(-1));
      this.show_radars_data.splice(deleteArr[0], deleteArr[deleteArr.length - 1]);
      this.radars();
    },
    change_open() {
      this.is_open = 0;
      this.is_null = 1;
      this.pradar = 0;
      this.radius = '45%';
      var chartDom = document.getElementById('myRadar');
      var myCharts = echarts.init(chartDom);
      myCharts.resize();

      this.radars(); //雷达图
    },
    // 控制数据展示
    change_show() {
      this.is_open = 1;
      this.is_null = 0;
      this.pradar = 1;
      this.radius = '58%';
      var chartDom = document.getElementById('myRadar');
      var myCharts = echarts.init(chartDom);
      myCharts.resize();

      this.radars(); //雷达图
    },
    // 雷达图数据
    radars() {
      var chartDom = document.getElementById('myRadar');
      var myCharts = echarts.init(chartDom);
      var options;
      options = {
        title: {},
        tooltip: {},
        // 产品名称
        legend: {
          show: false,
          origin: 'horizontal',
          data: this.attributeName,
          top: '0%',
          right: '4%',
          width: '60',
          height: '20',
          itemWidth: 9,
          itemHeight: 9,
          textStyle: {
            width: 80,
            overflow: 'break',
          },
        },
        // 数据属性名称
        radar: {
          indicator: this.show_radar_att_name,
          axisName: {
            fontSize: 16,
            width: 200,
          },
          center: ['50%', '46%'],
          radius: this.radius,
          splitNumber: 5,
        },
        grid: {
          show: false,
          left: '0%',
          right: '0%',
          top: '0%',
          bottom: '0%',
        },
        // 数据
        series: [
          //数据
          {
            name: '数据2',
            type: 'radar',
            data: this.show_radars_data,
          },
        ],
      };
      options && myCharts.setOption(options);
      window.addEventListener('resize', () => {
        myCharts.resize();
      });
    },
    //显示雷达图刻度
    show_radar_num() {
      let num = this.show_radar_att_name.length;
      //console.log(num);
      this.show_radar_att_name[num - 1].axisLabel.show = this.$store.state.line_num;
      //console.log(this.show_radar_att_name[num - 1]);
      this.radars();
    },
    //重置按钮操作
    Reset() {
      // this.$refs['cascader'].panel.clearCheckedNodes();
      this.values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let res =this.$store.state.lang =="cn" ? JSON.parse(JSON.stringify(this.$store.state.likeNameList)):  JSON.parse(JSON.stringify(this.$store.state.likeNameList_en));
      this.attributeName = res.slice(0, 10);
      this.managePaneldata();
      this.radars();
    },
  },
  created() {},
  mounted() {
    //! 检查数据大于8  截取
    //! 检查数据大于8  截取
    if(true){
          if(this.$store.state.productNameList.length > 8){
                this.$EventBus.$emit("RadarMap",8)
                console.log("触发事件");
          }
    }
    // 1.1
    //属性名称
    if (this.$store.state.likeNameList.length != 0) {
      this.attributeAllList =this.$store.state.lang =="cn" ? this.$store.state.likeNameList : this.$store.state.likeNameList_en
      //属性名称(维度)
      {
        let res = JSON.parse(JSON.stringify(this.attributeAllList));

        this.attributeName = res.slice(0, 10);
        //console.log("this.atrributeAll :>> ", this.atrributeAll);
        //console.log("this.attributeName :>> ", this.attributeName);
        this.show_radar_att_name = this.attributeName.map((item) => ({
          name: item,
          axisLabel: { show: false },
        }));
        this.radars();
        let that = this;
        let arr = [];
        res.forEach((item, index) => {
          // //console.log(item,index,'itemindex');
          let obj = {};
          obj.label = item;
          obj.value = index;
          arr[index] = obj;
        });
        //console.log("arr :>> ", arr);
        that.attributeAllList = arr;
        //console.log("that.attributeAllList :>> ", that.attributeAllList);
      }
    }
    //产品名称
    if (this.$store.state.likeProductList.length != 0) {
      this.show_productName = this.$store.state.likeProductList.slice(0, 8) || {};
      //console.log("this.show_productName :>> ", this.show_productName);
    }

    //分析数据
    if (this.$store.state.forcast_data.length != 0) {
      this.res_panelData = this.$store.state.forcast_data;
      //处理展示数据
      {
        //console.log("this.res_panelData :>> ", this.res_panelData);
        this.mid_product_data = [
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
        ];

        //将数据处理为单列数组，给页面展示
        for (let i = 0; i < this.mid_product_data.length; i++) {
          for (let j = 0; j < this.res_panelData.length; j++) {
            if (this.res_panelData[j][i] != undefined) {
              this.mid_product_data[i].panel_attributes_data.push(this.res_panelData[j][i].toFixed(2));
            }
          }
        }
        //console.log("this.mid_product_data :>> ", this.mid_product_data);
        let ten = [
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
        ];
        let middle = JSON.parse(JSON.stringify(this.mid_product_data));
        //console.log("this.middle :>> ", middle);
        //截取的数据10个数据长度,初始化展示data
        for (let i = 0; i < middle.length; i++) {
          ten[i].panel_attributes_data = middle[i].panel_attributes_data.splice(0, 10);
        }
        //console.log("ten :>> ", ten);
        this.show_productData = ten;
        //console.log("this.show_productData :>> ", this.show_productData);
        // 雷达图数据
        this.old_radars_data = [
           { value: [], name: '', max: 5 },
          { value: [], name: '', max: 5 },
          { value: [], name: '', max: 5 },
          { value: [], name: '', max: 5 },
          { value: [], name: '', max: 5 },
          { value: [], name: '', max: 5 },
          { value: [], name: '', max: 5 },
          { value: [], name: '', max: 5 },
        ];
        for (let index = 0; index < this.show_productName.length; index++) {
          this.old_radars_data[index].value = this.show_productData[index].panel_attributes_data;
          this.old_radars_data[index].name = this.show_productName[index].product;
        }
        this.show_radars_data = this.old_radars_data;

        //切除雷达图多余的数组
        let deleteArr = [];
        for (let x = 0; x < 8; x++) {
          if (this.show_radars_data[x].value.length == 0) {
            //console.log(x);
            deleteArr.push(x);
          }
        }
        //console.log("object :>> ", deleteArr[0], deleteArr.at(-1));
        this.show_radars_data.splice(deleteArr[0], deleteArr[deleteArr.length - 1]);
      }
    }

    //1.1end

    this.radars();
  },
  beforeDestroy() {
    this.$EventBus.$off('send_loading');
  },
  destroyed() {
    this.$store.commit('$_changeLine_num', false);
  },
};
</script>

<style lang="less" scopded>
@import url('../../style/History/radar3.less');
</style>
